/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-05-12",
    versionChannel: "nightly",
    buildDate: "2024-05-12T00:05:54.705Z",
    commitHash: "3d412c955ff2a84f02de7ef17be9eba70f3da5d2",
};
